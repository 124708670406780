import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import React from 'react';
import { ArrowUpward } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

interface IProps {
    sortingConfiguration: SortingConfiguration;
    setSortingConfiguration: (sortingConfiguration: SortingConfiguration) => void;
    setFilterType?: (value: string[]) => void;
}

type ColumnKey = 'username' | 'role' | 'type' | 'status' | 'actionDate';
type Order = 'asc' | 'desc';

export interface SortingConfiguration {
    order: Order;
    orderBy: ColumnKey;
}

export const UsersTableHead: React.FC<IProps> = (props) => {
    const { sortingConfiguration, setSortingConfiguration } = props;

    const { t } = useTranslation();

    const handleSortClick = (columnKey: ColumnKey) => {
        if (sortingConfiguration.orderBy === columnKey) {
            setSortingConfiguration({
                order: sortingConfiguration.order === 'asc' ? 'desc' : 'asc',
                orderBy: columnKey,
            });
        } else {
            setSortingConfiguration({
                order: 'asc',
                orderBy: columnKey,
            });
        }
    };

    const getColumnDirection = (columnKey: ColumnKey): Order => {
        if (sortingConfiguration.orderBy === columnKey) {
            return sortingConfiguration.order;
        }
        return 'asc';
    };

    const renderSortableHeaderCell = (columnKey: ColumnKey, label: string, className?: string) => {
        const isActive = (columKey: ColumnKey) => sortingConfiguration.orderBy === columKey;
        const getClassName = `${className ? className : ''}${isActive(columnKey) ? ' table-head-active' : ''}`;
        return (
            <TableCell className={getClassName}>
                <TableSortLabel
                    onClick={() => handleSortClick(columnKey)}
                    direction={getColumnDirection(columnKey)}
                    active={isActive(columnKey)}
                    IconComponent={ArrowUpward}
                >
                    {label}
                </TableSortLabel>
            </TableCell>
        );
    };

    return (
        <TableHead>
            <TableRow data-testid="usersTable">
                {renderSortableHeaderCell('username', t('complianceReports.usersTable.username'))}
                {renderSortableHeaderCell('role', t('complianceReports.usersTable.role'))}
                <TableCell>{t('complianceReports.usersTable.type')}</TableCell>
                {renderSortableHeaderCell('status', t('complianceReports.usersTable.status'))}
                {renderSortableHeaderCell('actionDate', t('complianceReports.usersTable.date'))}
            </TableRow>
        </TableHead>
    );
};
