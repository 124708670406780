import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableContainer } from '@material-ui/core';
import { Spinner, useQuery } from '@yonder-mind/ui-core';
import { SortingConfiguration, UsersTableHead } from './UsersTableHead';
import { UsersTableBody } from './UsersTableBody';
import { UsersTableItem } from '../domain/types';
import { ReportListKey } from '../DocumentChanges';
import { useSelectorUiWeb } from '../../../store';

interface UsersTableProps {
    selectedReportListKey: ReportListKey;
    isFile?: boolean;
}

export const UsersTable: React.FC<UsersTableProps> = ({ selectedReportListKey, isFile }) => {
    const [filterTypeValues, setFilterTypeValues] = useState<string[]>([]);

    const query = useQuery();
    const hasRoleSelected = query.get('selectedRole');
    const { t } = useTranslation();

    const [sortingConfiguration, setSortingConfiguration] = useState<SortingConfiguration>({
        order: 'asc',
        orderBy: 'username',
    });

    const filterUsersByType = (users: UsersTableItem[]) => {
        if (
            filterTypeValues.includes('all') ||
            (filterTypeValues.includes('read') && filterTypeValues.includes('readAck'))
        ) {
            return users;
        } else if (filterTypeValues.includes('read')) {
            return users.filter((user: UsersTableItem) => !user.ackRequired);
        } else if (filterTypeValues.includes('readAck')) {
            return users.filter((user: UsersTableItem) => user.ackRequired);
        }
        return users;
    };

    const { usersList, loading } = useSelectorUiWeb((state) => {
        return {
            usersList:
                filterUsersByType(
                    state.complianceReports.usersByChange[`${selectedReportListKey.oid}${selectedReportListKey.role}`]
                ) ?? [],
            loading: state.complianceReports.isLoadingUsersByChange,
        };
    });

    const setFilterType = (value: string[]) => {
        setFilterTypeValues(value);
    };

    return (
        <div className="users-table-wrapper">
            {loading ? (
                <div className="compliance-filter-no-items-wrapper">
                    <div className="compliance-no-filter-applied">
                        <Spinner />
                    </div>
                </div>
            ) : usersList.length ? (
                <div>
                    <div className="table-heading">
                        <p>
                            <b>
                                {isFile
                                    ? t('complianceReports.usersTable.headingFiles')
                                    : t('complianceReports.usersTable.heading')}
                            </b>
                        </p>
                    </div>
                    <div className="users-table-grow">
                        <TableContainer className="users-table" data-testid="userTable">
                            <Table size="small" stickyHeader={true}>
                                <UsersTableHead
                                    sortingConfiguration={sortingConfiguration}
                                    setSortingConfiguration={setSortingConfiguration}
                                    setFilterType={setFilterType}
                                />
                                <UsersTableBody usersList={usersList} sortingConfiguration={sortingConfiguration} />
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            ) : !hasRoleSelected ? (
                ''
            ) : (
                <div className="compliance-filter-no-items-wrapper">
                    <div className="compliance-no-filter-applied">
                        <p>{t('complianceReports.usersTable.notFound')}</p>
                    </div>
                </div>
            )}
        </div>
    );
};
