import React, { useMemo, useState } from 'react';
import { Table, TableContainer } from '@material-ui/core';
import { authSelector, SearchBar, Spinner } from '@yonder-mind/ui-core';
import { ChangesTableHead } from './ChangesTableHead';
import { ChangesTableBody } from './ChangesTableBody';
import { ChangesTableItem } from '../domain/types';
import { ReportListKey } from '../DocumentChanges';
import { useTranslation } from 'react-i18next';
import { complianceReportsActions, useSelectorUiWeb } from '../../../store';
import { useDispatch } from 'react-redux';

interface IProps {
    selectedDocumentId: string;
    users: string[];
    tags: string[];
    setReportListKey?: (change: ReportListKey) => void;
    reportListKey?: ReportListKey;
}

const ChangesTable: React.FC<IProps> = (props) => {
    const [filterTypeValues, setFilterTypeValues] = useState<string[]>([]);
    const [selectedRoles, setSelectedRoles] = useState<string[]>([]);

    const [searchTerm, setSearchTerm] = useState('');

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { selectedDocumentId, reportListKey, setReportListKey, users, tags } = props;

    const { documents, changesByContextVersion, isLoadingChanges, isLoadingDocuments, appliedFilterConfig } =
        useSelectorUiWeb((state) => state.complianceReports);
    const hasComplianceShowUserRole = useSelectorUiWeb(authSelector.hasComplianceShowUserRole);

    const filterChangesByType = (changes: ChangesTableItem[]) => {
        if (
            filterTypeValues.includes('all') ||
            (filterTypeValues.includes('read') && filterTypeValues.includes('readAck'))
        ) {
            return changes;
        } else if (filterTypeValues.includes('read')) {
            return changes.filter((change: ChangesTableItem) => change.readTotalCount);
        } else if (filterTypeValues.includes('readAck')) {
            return changes.filter((change: ChangesTableItem) => change.ackTotalCount);
        }
        return changes;
    };

    const filterChangesByRole = (changes: ChangesTableItem[]) => {
        return changes?.filter((c) => (selectedRoles.length > 0 ? selectedRoles.includes(c.role) : true));
    };

    const searchedChanges = changesByContextVersion[props.selectedDocumentId]?.filter(
        (change) =>
            change.chapter.toLowerCase().includes(searchTerm.toLowerCase()) ||
            change.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const changesList = useMemo(
        () => filterChangesByType(filterChangesByRole(searchedChanges)),
        [changesByContextVersion, props.selectedDocumentId, searchTerm, filterTypeValues]
    );

    const complianceDocument = documents.find((item) => item.contextVersionOid === selectedDocumentId);

    const onSearch = (searchValue: string) => {
        setSearchTerm(searchValue);
    };

    const onSearchCleared = () => {
        setSearchTerm('');
    };

    const setFilterType = (value: string[]) => {
        setFilterTypeValues(value);
    };

    const setChange = (reportListKey: ReportListKey) => {
        if (hasComplianceShowUserRole) {
            setReportListKey(reportListKey);
            dispatch(
                complianceReportsActions.complianceUsersRequested({
                    changeOid: reportListKey.oid,
                    role: reportListKey.role,
                    tagOids: tags ? tags : appliedFilterConfig.selectedTagIds,
                    users: users ? users : appliedFilterConfig.selectedUserIds,
                })
            );
        }
    };

    return (
        <div className="changes-table-wrapper">
            {isLoadingDocuments || isLoadingChanges ? (
                <div className="compliance-filter-no-items-wrapper">
                    <div className="compliance-no-filter-applied">
                        <Spinner />
                    </div>
                </div>
            ) : changesList && complianceDocument ? (
                <div>
                    <div className="table-heading">
                        <p>
                            <b>{t('complianceReports.changesTable.heading')}</b>
                        </p>
                    </div>
                    <div className="search-and-table-wrapper">
                        <SearchBar
                            className={`item-selector-search-bar ${changesList.length === 0 ? 'error' : ''}`}
                            onSearchRequested={onSearch}
                            onSearchCleared={onSearchCleared}
                            onInputChanged={onSearch}
                            placeholder={t('complianceReports.changesTable.searchPlaceholder')}
                        />
                        {changesList.length === 0 ? (
                            <div className="compliance-filter-no-items-wrapper">
                                <p>{t('complianceReports.noResults')}</p>
                            </div>
                        ) : (
                            <div className="table-wrapper">
                                <TableContainer className="changes-table" data-testid="changeTasksTable">
                                    <Table size="small" stickyHeader={true}>
                                        <ChangesTableHead
                                            setFilterType={setFilterType}
                                            availableRoles={[...new Set(searchedChanges.map((c) => c.role))]}
                                            setSelectedRoles={setSelectedRoles}
                                            selectedRoles={selectedRoles}
                                        />
                                        <ChangesTableBody
                                            selectedChange={reportListKey}
                                            setSelectedChange={setChange}
                                            chaptersList={changesList}
                                        />
                                    </Table>
                                </TableContainer>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div className="compliance-filter-no-items-wrapper">
                    <div className="compliance-no-filter-applied">
                        <p>{t('complianceReports.changesTable.notFound')}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChangesTable;
