import React, { useState } from 'react';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import DateFnsUtils from '@date-io/dayjs';

import { useDispatch } from 'react-redux';
import { complianceReportsActions, useSelectorUiWeb } from '../../../store';
import { useQuery } from '@yonder-mind/ui-core';
import { useParams } from 'react-router';
import { AccessTime } from '@material-ui/icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const DateSelector: React.FC<{ defaultDate?: string }> = ({ defaultDate }) => {
    const query = useQuery();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { status }: { status: string } = useParams();
    const selectedDate = query.get('date');
    const selectedStatus = useSelectorUiWeb((state) => state.complianceReports.filterConfig.status);

    const present = selectedStatus === 'PRESENT';
    const future = selectedStatus === 'FUTURE';
    const past = selectedStatus === 'PAST';

    const dateFromFilter = useSelectorUiWeb((state) => state.complianceReports.dateConfig.effectiveAt);
    const currentFilterConfig = useSelectorUiWeb((state) => state.complianceReports.appliedFilterConfig);

    const setDefaultDate = () => {
        if (selectedDate) {
            return dayjs(selectedDate).utc(true);
        }
        if (dateFromFilter) {
            return dayjs(dateFromFilter).utc(true);
        }
        return dayjs(defaultDate).utc(true);
    };

    const [dateFrom, setDateFrom] = useState<dayjs.Dayjs | null>(setDefaultDate());

    React.useEffect(() => {
        if (selectedStatus) {
            setDateFrom(setDefaultDate());
        }
    }, [selectedStatus]);

    const setDate = (date: dayjs.Dayjs | null) => {
        if (date && date.isValid()) {
            setDateFrom(date.utc(true));
        }
    };

    React.useEffect(() => {
        if (past) {
            dispatch(
                complianceReportsActions.setDateToGetComplianceFilterDocuments(dayjs(dateFrom).toISOString(), false)
            );
        }
        if (future) {
            dispatch(complianceReportsActions.setDateToGetComplianceFilterDocuments('', true));
        }
        if (present) {
            dispatch(complianceReportsActions.setDateToGetComplianceFilterDocuments('', false));
        }
    }, [dateFrom]);

    const datePickerPlaceholder = future ? t('complianceReports.datepicker.openEnded') : 'DD.MM.YYYY';

    return (
        <div className="datepicker-wrapper">
            <p className="compliance-heading">
                <b>{`${t(`complianceReports.effectivityHeader.${currentFilterConfig.status.toLowerCase()}`)}`} </b>
                {future
                    ? t('complianceReports.effectivityHeader.futureDate', {
                          date: dayjs().format('DD.MM.YYYY'),
                      })
                    : ''}
            </p>
            <div className="present-compliance-current-time">
                {!present && !future && dateFrom && (
                    <MuiPickersUtilsProvider libInstance={dayjs} utils={DateFnsUtils}>
                        <div className="datepicker-select">
                            <KeyboardDatePicker
                                placeholder={datePickerPlaceholder}
                                InputAdornmentProps={{ position: 'start' }}
                                format="DD.MM.YYYY"
                                variant="inline"
                                inputVariant="outlined"
                                disableToolbar={true}
                                value={dateFrom}
                                onChange={(e: dayjs.Dayjs) => setDate(e)}
                                autoOk={true}
                                disabled={!status}
                                maxDate={past ? dayjs().subtract(1, 'day') : null}
                                data-testid="datePicker"
                            />
                        </div>
                        <div className="datepicker-select time-select">
                            <KeyboardTimePicker
                                placeholder="HH:mm"
                                InputAdornmentProps={{ position: 'start' }}
                                format="HH:mm"
                                variant="inline"
                                inputVariant="outlined"
                                disableToolbar={true}
                                ampm={false}
                                value={dateFrom}
                                onChange={(e: dayjs.Dayjs) => setDate(e)}
                                autoOk={true}
                                disabled={!status}
                                keyboardIcon={<AccessTime />}
                                initialFocusedDate={dateFrom}
                                InputProps={{ readOnly: true }}
                                data-testid="timePicker"
                            />
                        </div>
                    </MuiPickersUtilsProvider>
                )}
            </div>
        </div>
    );
};
