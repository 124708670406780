import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { UrlParam } from '../ComplianceReports';
import { useHistory } from 'react-router';
import { authSelector, useQuery } from '@yonder-mind/ui-core';
import { PresetsList } from '../Preset/PresetsList';
import { useDispatch, useSelector } from 'react-redux';
import { complianceReportsActions, useSelectorUiWeb } from '../../../store';

export const StatusSidebar: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const userInfo = useSelector(authSelector.userInfo);

    const [status, setStatus] = React.useState('');

    const getComplianceReport = (value: string) => {
        history.replace(value);
    };

    const params: UrlParam = useParams();
    const query = useQuery();

    const selectedStatus = query.get('status');

    const { presets, isLoadingPresets, isDeletingPreset } = useSelectorUiWeb((state) => state.complianceReports);

    React.useEffect(() => {
        if (userInfo.preferred_username) {
            dispatch(complianceReportsActions.compliancePresetsRequested(userInfo.preferred_username));
        }
    }, [userInfo.preferred_username]);

    React.useEffect(() => {
        setStatus(selectedStatus ? selectedStatus.toLowerCase() : params.status);
    }, [selectedStatus, params]);

    return (
        <div className="status-sidebar">
            <div>
                <div className="status-wrapper">
                    <p className="sidebar-title">
                        <b>{t('complianceReports.sidebar.status')}</b>
                    </p>
                    <div
                        onClick={() => getComplianceReport('/compliance-reports/present')}
                        className={`sidebar-button ${status === 'present' ? 'active' : null}`}
                        data-testid="current"
                    >
                        <p>{t('complianceReports.sidebar.present')}</p>
                    </div>
                    <div
                        onClick={() => getComplianceReport('/compliance-reports/future')}
                        className={`sidebar-button ${status === 'future' ? 'active' : null}`}
                        data-testid="upcoming"
                    >
                        <p>{t('complianceReports.sidebar.future')}</p>
                    </div>
                    <div
                        onClick={() => getComplianceReport('/compliance-reports/past')}
                        className={`sidebar-button ${status === 'past' ? 'active' : null}`}
                        data-testid="past"
                    >
                        <p>{t('complianceReports.sidebar.past')}</p>
                    </div>
                </div>
                <PresetsList presets={presets} isLoading={isLoadingPresets || isDeletingPreset} />
            </div>
        </div>
    );
};

export default StatusSidebar;
