import React from 'react';
import { TableBody, TableCell, TableRow } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { ChangesTableItem } from '../domain/types';
import { ProgressBar } from '../DocumentsTable/ProgressBar';
import { useTranslation } from 'react-i18next';
import { ReportListKey } from '../DocumentChanges';
import { authSelector } from '@yonder-mind/ui-core';
import { useSelectorUiWeb } from '../../../store';

interface IProps {
    chaptersList: ChangesTableItem[];
    setSelectedChange?: (complianceReportKey: ReportListKey) => void;
    selectedChange?: ReportListKey;
}

export const ChangesTableBody: React.FC<IProps> = (props) => {
    const { chaptersList, setSelectedChange, selectedChange } = props;
    const { t } = useTranslation();
    const hasComplianceShowUserRole = useSelectorUiWeb(authSelector.hasComplianceShowUserRole);

    const { status } = useSelectorUiWeb((state) => state.complianceReports.filterConfig);
    const getReportListKey = (changesTableItem: ChangesTableItem): ReportListKey => {
        return { role: changesTableItem.role, oid: changesTableItem.changeOid };
    };

    const isEqualReportListKey = (key: ReportListKey, change: ChangesTableItem) =>
        key.oid === change.changeOid && key.role === change.role;

    return (
        <TableBody>
            {chaptersList.map((item) => {
                const isSelected = isEqualReportListKey(selectedChange, item);
                return (
                    <TableRow
                        className={`${isSelected ? 'table-item-selected' : ''}`}
                        key={'table-item' + item.changeOid + item.role}
                        onClick={() => setSelectedChange(getReportListKey(item))}
                        data-testid="changesTableRow"
                    >
                        <TableCell>{item.chapter}</TableCell>
                        <TableCell>{item.title}</TableCell>
                        <TableCell>
                            {item.ackTotalCount
                                ? t('complianceReports.documentsTable.readAndAck')
                                : t('complianceReports.documentsTable.read')}
                        </TableCell>
                        <TableCell size="small">
                            {item.ackTotalCount ? (
                                <ProgressBar
                                    currentAmount={item.ackDoneCount}
                                    totalAmount={item.ackTotalCount}
                                    futureUse={status === 'FUTURE'}
                                />
                            ) : (
                                <ProgressBar
                                    currentAmount={item.readDoneCount}
                                    totalAmount={item.readTotalCount}
                                    futureUse={status === 'FUTURE'}
                                />
                            )}
                        </TableCell>
                        <TableCell>{item.role}</TableCell>
                        {
                            <TableCell className={`arrow-right ${isSelected ? 'selected' : ''}`}>
                                {hasComplianceShowUserRole && <ChevronRight />}
                            </TableCell>
                        }
                    </TableRow>
                );
            })}
        </TableBody>
    );
};
