import React from 'react';
import { TableBody, TableCell, TableRow } from '@material-ui/core';

import { UsersTableItem } from '../domain/types';
import { SortingConfiguration } from './UsersTableHead';
import { useTranslation } from 'react-i18next';
import uniqueId from 'lodash/uniqueId';
import { DateUtils } from '@yonder-mind/ui-core';
import dayjs from 'dayjs';

interface IProps {
    usersList: UsersTableItem[];
    sortingConfiguration: SortingConfiguration;
}

export const UsersTableBody: React.FC<IProps> = (props) => {
    const { usersList, sortingConfiguration } = props;
    const { t } = useTranslation();

    const sortComparator = (a: UsersTableItem, b: UsersTableItem) => {
        const { order, orderBy } = sortingConfiguration;
        if (orderBy === 'username' || orderBy === 'role') {
            if (a.username.toLowerCase() < b.username.toLowerCase()) {
                return order === 'asc' ? -1 : 1;
            } else if (a.role?.toLowerCase() < b.role?.toLowerCase()) {
                return order === 'asc' ? -1 : 1;
            }
            return order === 'asc' ? 1 : -1;
        }
        if (orderBy === 'actionDate') {
            if (dayjs(a.actionDate).millisecond() < dayjs(b.actionDate).millisecond()) {
                return order === 'asc' ? -1 : 1;
            }
            if (dayjs(a.actionDate).millisecond() > dayjs(b.actionDate).millisecond()) {
                return order === 'asc' ? 1 : -1;
            }
        }
        if (a[orderBy] < b[orderBy]) {
            return order === 'asc' ? -1 : 1;
        }
        if (a[orderBy] > b[orderBy]) {
            return order === 'asc' ? 1 : -1;
        }
        return 0;
    };

    return (
        <TableBody>
            {usersList.sort(sortComparator).map((item) => {
                return (
                    <TableRow key={'users-table-item' + uniqueId()} data-testid="usersTableRow">
                        <TableCell>{item.username}</TableCell>
                        <TableCell>{item.role ? item.role : '—'}</TableCell>
                        <TableCell>
                            {item.ackRequired
                                ? t('complianceReports.documentsTable.readAndAck')
                                : t('complianceReports.documentsTable.read')}
                        </TableCell>
                        <TableCell>
                            {item.status ? (
                                <div className="status-circle green" />
                            ) : (
                                <div className="status-circle red" />
                            )}
                        </TableCell>
                        <TableCell>
                            {item.actionDate ? DateUtils.ISOToFormattedDateWithoutTime(item.actionDate) : 'N/A'}
                        </TableCell>
                    </TableRow>
                );
            })}
        </TableBody>
    );
};
